
import { PropType } from "vue";
import { Component, Vue } from "vue-property-decorator";
import { IUser } from "@/types/User";
import { ITruckDriver } from "@/types/TruckDriver";

const DispatchProps = Vue.extend({
  props: {
    modal: Object,
    driver: Object,
    plan: Object,
    drivers: Array as PropType<ITruckDriver[]>,
    users: Array as PropType<IUser[]>,
    dispatch: Object,
    dispatchOverlay: Object,
  },
});

@Component({
  name: "DispatchModal",
})
export default class DispatchModal extends DispatchProps {
  truckNumberState = null;
  fdpState = null;
  licenceState = null;
  quantityState = null;
  dateState = null;
  newBalance = this.plan.balance;
  maxQty = this.plan.balance * 20;
  tonnage = 0;
  save(): void {
    this.dispatch.loadingPlan = this.plan;
    this.dispatch.loadingPlanId = this.plan.id;
    this.plan.balance = this.newBalance;
    this.tonnage = 0;
    this.$emit("save:dispatch", this.dispatch);
  }
  changeDriver(): void {
    const name = this.dispatch.driver.name;
    const allDrivers = this.drivers;
    this.dispatch.driverId = 0;
    this.dispatch.driver.id = 0;
    if (Array.isArray(allDrivers)) {
      const selectedDrivers = allDrivers.filter(function (selectedDriver) {
        if (selectedDriver.name == name) {
          return selectedDriver.licence;
        }
      });
      if (selectedDrivers.length > 0) this.dispatch.driver = selectedDrivers[0];
    }
  }
  changeLicence(): void {
    const licence = this.dispatch.driver.licence;
    const allDrivers = this.drivers;
    this.dispatch.driverId = 0;
    this.dispatch.driver.id = 0;
    if (Array.isArray(allDrivers)) {
      const selectedDrivers = allDrivers.filter(function (selectedDriver) {
        if (selectedDriver.licence == licence) {
          return selectedDriver.licence;
        }
      });
      if (selectedDrivers.length > 0) this.dispatch.driver = selectedDrivers[0];
    }
  }
  changeBalance(): void {
    const quantity = this.dispatch.quantity;
    const balance = this.plan.balance;
    this.tonnage = quantity / 20;
    this.newBalance = balance - this.tonnage;
  }
  getUserById(id: string): string {
    const users: IUser[] = this.users;
    const found = users.find((element) => element.id == id);
    if (found != undefined) return found.userName || "unknown";
    return "unknown";
  }
}
