
import { Component, Vue } from "vue-property-decorator";
@Component({
  name: "ConfirmDeleteModal",
  components: {},
  props: {
    modal: Object,
    confirmDelete: {},
  },
})
export default class confirmDeleteModal extends Vue {}
